import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl, injectIntl } from 'react-intl';
import { getReportRequests, setReportRequest } from '../../api';

import Loading from '../Common/Loading';
import Pagination from '../Common/Pagination';
import SearchRow from './SearchRow';
import { NotificationsCTX } from '../../contexts/Notification';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import Button from '../Common/Button';
import makeReportRequestPayload from './reportTypes';
import Heading from '../Common/Page/Heading';
import messages from './messages';
import { Report } from '../../core/types';
import Spinner from '../../images/Spinner';

type THType = {
  text: string;
  className?: string;
};

type ReportsTBody = {
  data: Array<Report>;
};

type ReportsTable = {
  data: Array<Report>;
  formatMessage: any;
};

const StatusList = [
  { name: 'pending', color: 'bg-poltio-yellow/70' },
  { name: 'working', color: 'bg-poltio-purple/70' },
  { name: 'finished', color: 'bg-green-400' },
  { name: 'failed', color: 'bg-red-400' },
];

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(' ');
}

function linkFormatter(cell: string, formatMessage: any) {
  if (!cell) return null;

  return (
    <Button.Primary
      type="button"
      className="flex flex-row-reverse w-2/3 justify-items-center gap-x-2"
      href={`https://s3-eu-west-1.amazonaws.com/poltio-reports/${cell}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <ArrowDownTrayIcon className="w-5 h-5 text-white" aria-hidden="true" />
      {formatMessage(messages.Download)}
    </Button.Primary>
  );
}

function TH(props: THType) {
  return (
    <th
      scope="col"
      className={`px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ${props.className}`}
    >
      {props.text}
    </th>
  );
}

function ReportsTBody(props: ReportsTBody) {
  const intl = useIntl();
  const { data } = props;

  return (
    <tbody>
      {data.map((report: Report, i: number) => (
        <tr key={i} className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
          <td className="hidden px-6 py-6 text-sm text-gray-500 whitespace-nowrap lg:flex">
            <div className="text-sm font-medium text-gray-900">{report.id}</div>
          </td>
          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
            <div className="text-sm font-medium text-gray-900">
              {linkFormatter(report.report_file, intl.formatMessage)}
            </div>
          </td>
          <td className="px-6 py-4 text-sm text-gray-900  whitespace-nowrap">
            <div
              className={classNames(
                `${
                  StatusList.filter((s) => s.name === report.status)[0].color
                } font-medium text-sm rounded-md w-max flex gap-x-2 items-center`
              )}
            >
              &nbsp;{report.status}&nbsp;
              {report.status === 'working' || report.status === 'pending' ? (
                <Spinner />
              ) : null}
            </div>
          </td>
          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
            <div className="text-sm font-medium text-gray-900">
              {report.runtime}
            </div>
          </td>
          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
            <div className="text-sm font-medium text-gray-900">
              <span>{intl.formatDate(report.created_at)}</span>{' '}
              <span>{intl.formatTime(report.created_at)}</span>
            </div>
          </td>
          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
            <div className="text-sm font-medium text-gray-900">
              <span>{intl.formatDate(report.updated_at)}</span>{' '}
              <span>{intl.formatTime(report.updated_at)}</span>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
}

function ReportsTable(props: ReportsTable) {
  const { data, formatMessage } = props;
  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
      <div className="flex flex-col py-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <TH text="ID" className="hidden lg:flex" />
                    <TH text={formatMessage(messages.File)} />
                    <TH text={formatMessage(messages.Status)} />
                    <TH text={formatMessage(messages.RunTime)} />
                    <TH text={formatMessage(messages.CreatedAt)} />
                    <TH text={formatMessage(messages.UpdatedAt)} />
                  </tr>
                </thead>
                <ReportsTBody data={data} />
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Reports = ({ intl: { formatMessage } }: any) => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({
    totalSize: 100,
    page: 1,
    sizePerPage: 12,
    lastPage: 1,
  });

  const reportData = useMemo(() => location?.state, [location?.state]);
  const [typeValue, setType] = useState(reportData?.report || '');
  const [idValue, setID] = useState(
    reportData?.public_id || reportData?.base_id || ''
  );
  const [downloading, setDownloading] = useState(false);
  const [initialDownload, setInitialDownload] = useState(
    reportData?.report && (reportData?.public_id || reportData?.base_id)
      ? true
      : false
  );

  const { success, error } = useContext(NotificationsCTX);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const { data } = await getReportRequests(
      pageData.page,
      pageData.sizePerPage
    );
    setData(data.data);
    setLoading(false);
    setPageData((p) => {
      return {
        ...p,
        page: p.page,
        sizePerPage: p.sizePerPage,
        totalSize: data.total,
        lastPage: data.last_page,
      };
    });
    setID('');
    setType('');
  }, [pageData.page, pageData.sizePerPage]);

  const handlePageChange = (page: number, sizePerPage: number) => {
    setPageData({
      ...pageData,
      page,
      sizePerPage,
    });
  };

  useEffect(() => {
    if (!reportData?.report || !reportData?.public_id) {
      fetchData();
    }
  }, [fetchData, reportData?.report, reportData?.public_id]);

  const downloadRequest = useCallback(async () => {
    if (!idValue || !typeValue) return;
    setDownloading(true);
    setInitialDownload(false);
    try {
      await setReportRequest(makeReportRequestPayload(typeValue, idValue));

      success('Report request submitted.');

      setDownloading(false);
      fetchData();

      window.history.replaceState({}, document.title);
    } catch (e) {
      error('Error occured!');
      setDownloading(false);
    }
  }, [error, success, idValue, typeValue, fetchData]);

  useEffect(() => {
    if (initialDownload && !downloading) {
      downloadRequest();
    }
  }, [initialDownload, downloading, downloadRequest]);

  return (
    <div>
      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Heading
          title={formatMessage(messages.Reports)}
          body={formatMessage(messages.ReportsDesc)}
        />
      </div>
      <div className="flex items-end max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <SearchRow
          downloadRequest={downloadRequest}
          downloading={downloading}
          setType={setType}
          setID={setID}
          typeValue={typeValue}
          idValue={idValue}
        />
      </div>

      <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
        <Pagination
          last={pageData.lastPage}
          active={pageData.page}
          size={pageData.sizePerPage}
          handlePageChange={handlePageChange}
          totalSize={pageData.totalSize}
          showTotal
        />
      </div>
      {loading ? (
        <div className="max-w-7xl mx-auto px-2">
          <Loading />
        </div>
      ) : (
        <>
          <ReportsTable formatMessage={formatMessage} data={data} />
          <div className="max-w-7xl mx-auto px-2 sm:px-6 md:px-8">
            <Pagination
              last={pageData.lastPage}
              active={pageData.page}
              size={pageData.sizePerPage}
              handlePageChange={handlePageChange}
              totalSize={pageData.totalSize}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default injectIntl(Reports);
