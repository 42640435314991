import { useEffect, useState, useCallback, type FC } from 'react';
import { injectIntl } from 'react-intl';
import { getDashboard, getDashboardStats } from '../../api';
import Contents from './Contents';
import ContentStats from './ContentStats';
import Stats from './Stats';
import dayjs from 'dayjs';
import DetailCard from '../Common/DetailCard';

import messages from './messages';
import Loading from '../Common/Loading';
import VoteGraph from './VoteGraphs';
import Heading from '../Common/Page/Heading';
import Button from '../Common/Button';
import {
  SwatchIcon,
  PencilIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';

type Props = {
  intl: any;
};

const Home: FC<Props> = ({ intl }) => {
  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dashboardStats, setDashboardStats] = useState({});
  const [datesChanged, setDatesChanged] = useState(false);

  const fetchData = useCallback(async () => {
    const { data: resp } = await getDashboard();
    setContents(resp.content);
    setLoading(false);
  }, []);

  const getStats = useCallback(async () => {
    if (!loading) {
      const data = await getDashboardStats(
        datesChanged ? dayjs(startDate).format('YYYY-MM-DD') : null,
        datesChanged ? dayjs(endDate).format('YYYY-MM-DD') : null
      );
      setDashboardStats(data.data.stats);
    } else {
      return;
    }
  }, [endDate, startDate, loading, datesChanged]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getStats();
  }, [startDate, endDate, getStats]);

  return loading ? (
    <Loading />
  ) : (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Heading title={intl.formatMessage(messages.Home)} />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 space-y-4">
        <div className="py-4 grid md:flex gap-y-5 gap-x-5 text-white">
          <Button
            className="w-full p-8 rounded-lg py-16 grid gap-y-3 text-center bg-poltio-blue focus:ring-poltio-blue hover:bg-poltio-blue-400"
            href={'/editor/new'}
          >
            <PencilIcon className="mx-auto h-8 w-8" />
            <span className="text-lg">
              {intl.formatMessage(messages.CreateNew)}
            </span>
            <span className="text-xs text-gray-200 font-light">
              {intl.formatMessage(messages.StartFromScratch)}
            </span>
          </Button>
          <Button
            className="w-full p-8 rounded-lg py-16 grid gap-y-3 text-center  bg-poltio-purple focus:ring-poltio-purple hover:bg-poltio-purple-hover"
            href={'/editor'}
          >
            <SwatchIcon className="mx-auto h-8 w-8" />
            <span className="text-lg">
              {intl.formatMessage(messages.DiscoverTemplates)}
            </span>
            <span className="text-xs text-gray-200 font-light">
              {intl.formatMessage(messages.UseTemplate)}
            </span>
          </Button>
          <Button
            className="w-full rounded-lg p-8 py-16 grid gap-y-3 text-center bg-poltio-red focus:ring-poltio-red hover:bg-poltio-red-hover"
            href={'/sage'}
          >
            <SparklesIcon className="mx-auto h-8 w-8" />
            <span className="text-lg">SAGE (Beta)</span>
            <span className="text-xs text-gray-200 font-light">
              {intl.formatMessage(messages.UseAI)}
            </span>
          </Button>
        </div>
        <div className="py-4">
          <Contents contents={contents} fetchContents={fetchData} />
        </div>
        <div className="py-4">
          <Stats
            intl={intl}
            messages={messages}
            datesChanged={datesChanged}
            setDatesChanged={setDatesChanged}
            dashboardStats={dashboardStats}
            setStartDate={setStartDate}
            startDate={startDate}
            setEndDate={setEndDate}
            endDate={endDate}
          />
        </div>
        <DetailCard
          title={intl.formatMessage(messages.MetricsOverview)}
          subtitle={intl.formatMessage(messages.MetricsDesc)}
          datesChanged={datesChanged}
          startDate={`${intl.formatMessage(messages.StartDate)}: ${dayjs(
            startDate
          ).format('YYYY-MM-DD')}`}
          endDate={`${intl.formatMessage(messages.EndDate)}: ${dayjs(
            endDate
          ).format('YYYY-MM-DD')}`}
        >
          <VoteGraph
            intl={intl}
            messages={messages}
            datesChanged={datesChanged}
            startDate={startDate}
            endDate={endDate}
          />
        </DetailCard>
        <DetailCard title={intl.formatMessage(messages.ContentStats)}>
          <ContentStats intl={intl} messages={messages} />
        </DetailCard>
      </div>
    </div>
  );
};

export default injectIntl(Home);
